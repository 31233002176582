import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  Cell,
  FilterButtonSkeleton,
  Flex,
  SubheaderSkeleton,
  VStack,
} from '@revolut/ui-kit'

import { useGetEngagementSurvey } from '@src/api/engagement'
import Table from '@src/components/TableV2/Table'
import TableLoader from '@src/components/TableV2/TableLoader'
import { NoSurveysPlaceholder } from '@src/features/Engagement/components/NoSurveysPlaceholder'
import { useIsEngagementLayoutV2 } from '@src/features/Engagement/hooks/permissions'
import TabBarNavigation, {
  TabBarTableNavigationProps,
} from '@src/features/TabBarNavigation/TabBarNavigation'
import { FilterByInterface } from '@src/interfaces/data'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { useSubtabs } from '../../hooks/useSubtabs'
import {
  useSurveyRoundSelector,
  UseSurveyRoundSelectorReturnType,
} from '../Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimelineFilterInterface } from '../Rounds/Selector/types'
import { SurveySubheader } from '../Subheader'
import { EngagementInsightsWidget } from './InsightsWidget'
import { EngagementResultsWidget } from './ResultsWidget'
import {
  useSurveySelector,
  UseSurveySelectorReturnType,
} from './ResultsWidget/SurverySelector'
import { useTimelineFilter } from './ResultsWidget/TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './ResultsWidget/hooks/useHeatmapFilters'

const WithSubheader = ({
  isLoading,
  surveyId,
  subheaderRoundData,
  scopeFilters,
  timelineFilter,
  subtabs,
  children,
}: Pick<Props, 'subheaderRoundData' | 'scopeFilters'> & {
  isLoading?: boolean
  surveyId: number | undefined
  timelineFilter: TimelineFilterInterface
  subtabs: TabBarTableNavigationProps['tabs']
  children: React.ReactNode
}) => {
  return (
    <VStack space="s-24">
      {isLoading && (
        <Cell>
          <SubheaderSkeleton />
        </Cell>
      )}
      {subheaderRoundData && (
        <>
          <SurveySubheader
            isNestedRoundSelector
            roundSelectorData={subheaderRoundData}
            scopeFilters={scopeFilters}
          />
          {surveyId && subheaderRoundData.round.value?.id && (
            <EngagementInsightsWidget
              surveyId={surveyId}
              insightType="survey"
              surveyRoundId={subheaderRoundData.round.value?.id}
              datesRange={{
                dateFrom: timelineFilter.dateFrom,
                dateTo: timelineFilter.dateTo,
              }}
              scopeParams={groupFiltersIntoURLQuery(scopeFilters)}
            />
          )}
          <TabBarNavigation tabs={subtabs} />
        </>
      )}
      {children}
    </VStack>
  )
}

const TablePlaceholder = ({
  isLoading,
  isCompany,
  isLayoutV2,
}: {
  isLayoutV2: boolean
  isLoading?: boolean
  isCompany?: boolean
}) => {
  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          {isCompany ? (
            <CompanyNavigation />
          ) : !isLayoutV2 && isLoading ? (
            <FilterButtonSkeleton width={250} />
          ) : null}
          {isLoading && <FilterButtonSkeleton width={150} />}
        </Flex>
      </Table.Widget.Info>
      {isLoading && (
        <Table.Widget.Filters>
          <FilterButtonSkeleton width={150} />
        </Table.Widget.Filters>
      )}
      <Table.Widget.Table>
        {isLoading ? <TableLoader loading rowHeight="large" /> : <NoSurveysPlaceholder />}
      </Table.Widget.Table>
    </Table.Widget>
  )
}

interface Props {
  entityId: number | undefined
  entityPermissions: string[] | undefined
  isCompany?: boolean
  scopeFilters: FilterByInterface[] | undefined
  subheaderRoundData?: UseSurveyRoundSelectorReturnType
  timelineFilter?: TimelineFilterInterface
  surveySelector?: UseSurveySelectorReturnType
}

const SurveyResultsPublishedInner = ({
  entityId,
  entityPermissions,
  scopeFilters,
  isCompany,
  subheaderRoundData,
  surveySelector,
  timelineFilter: timelineFilterFromProps,
}: Props) => {
  const isLayoutV2 = useIsEngagementLayoutV2({ isOrganisationCompanySubtab: isCompany })
  const surveyId = surveySelector?.value?.id || subheaderRoundData?.survey.value?.id

  const {
    data: survey,
    isLoading: isLoadingSurvey,
    refetch: refetchSurvey,
  } = useGetEngagementSurvey(surveyId)

  const timelineFilter = (subheaderRoundData ||
    timelineFilterFromProps) as TimelineFilterInterface
  const heatmapFilters = useHeatmapFilters(surveyId, scopeFilters)
  const subtabs = useSubtabs(entityId, entityPermissions, true)

  const subheaderProps = {
    surveyId,
    subheaderRoundData,
    scopeFilters,
    timelineFilter,
    subtabs,
    isLoading: isLoadingSurvey,
  }

  if (isLoadingSurvey) {
    const Wrapper = isLayoutV2 ? WithSubheader : React.Fragment

    return (
      <Wrapper {...subheaderProps}>
        <TablePlaceholder isLoading isCompany={isCompany} isLayoutV2={isLayoutV2} />
      </Wrapper>
    )
  }
  if (survey) {
    return (
      <WithSubheader {...subheaderProps}>
        {isLayoutV2 ? (
          <Switch>
            {subtabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component
                  survey={survey}
                  refetchSurvey={refetchSurvey}
                  timelineFilter={timelineFilter}
                  heatmapFilters={heatmapFilters}
                  scopeFilters={scopeFilters}
                />
              </Route>
            ))}
          </Switch>
        ) : (
          <EngagementResultsWidget
            survey={survey}
            itemsToAnalyse={isLayoutV2 ? 'categories' : undefined}
            refetchSurvey={refetchSurvey}
            timelineFilter={timelineFilter}
            heatmapFilters={heatmapFilters}
            scopeFilters={scopeFilters}
            surveySelector={surveySelector}
            isCompany={isCompany}
          />
        )}
      </WithSubheader>
    )
  }
  return (
    <WithSubheader {...subheaderProps}>
      <TablePlaceholder isCompany={isCompany} isLayoutV2={isLayoutV2} />
    </WithSubheader>
  )
}

const SurveyResultsPublishedWithoutSubheader = (props: Props) => {
  const surveySelector = useSurveySelector()
  const timelineFilter = useTimelineFilter(surveySelector.value?.id)

  return (
    <SurveyResultsPublishedInner
      {...props}
      surveySelector={surveySelector}
      timelineFilter={timelineFilter}
    />
  )
}

const SurveyResultsPublishedWithSubheader = (props: Props) => {
  const subheaderRoundData = useSurveyRoundSelector()
  return (
    <SurveyResultsPublishedInner {...props} subheaderRoundData={subheaderRoundData} />
  )
}

export const SurveyResultsPublished = (props: Props) => {
  const isLayoutV2 = useIsEngagementLayoutV2({
    isOrganisationCompanySubtab: props.isCompany,
  })
  if (isLayoutV2) {
    return <SurveyResultsPublishedWithSubheader {...props} />
  }
  return <SurveyResultsPublishedWithoutSubheader {...props} />
}
