import React from 'react'
import { Color, Token, Tooltip, useTooltip } from '@revolut/ui-kit'

import {
  EngagementResultInterface,
  EngagementResultsAverageScoreInterface,
} from '@src/features/Engagement/api/analytics/interfaces'
import { PreviewBar } from './PreviewBar'
import { Summary } from './RatingTooltipContent/Summary'

type Distribution = {
  color: Color
  width: number
}

const getAvgDistribution = ({
  averageScore,
}: {
  averageScore: EngagementResultsAverageScoreInterface
}): Distribution[] => {
  const ambivalentsRate =
    1 -
    averageScore.detractors_rate -
    averageScore.passives_rate -
    averageScore.promoters_rate

  return [
    {
      color: Token.color.danger,
      width: averageScore.detractors_rate,
    },
    {
      color: Token.color.yellow,
      width: averageScore.passives_rate,
    },
    {
      color: Token.color.success,
      width: averageScore.promoters_rate,
    },
    {
      color: Token.color.greyTone20,
      width: ambivalentsRate > 0 ? ambivalentsRate : 0,
    },
  ]
}

interface Props {
  data: EngagementResultInterface
}
export const DistributionChart = ({ data }: Props) => {
  const tooltip = useTooltip()

  const avgDistribution = getAvgDistribution({ averageScore: data })

  if (data.type === 'Open ended') {
    return <>-</>
  }
  return (
    <>
      <PreviewBar tooltip={tooltip} distribution={avgDistribution} />
      <Tooltip {...tooltip.getTargetProps()}>
        <Summary averageScore={data} />
      </Tooltip>
    </>
  )
}
