import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ActionButton, Widget } from '@revolut/ui-kit'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { ROUTES } from '@src/constants/routes'
import { TimelineFilterInterface } from '@src/pages/Performance/Engagement/components/Rounds/Selector/types'
import { useIsEngagementManager } from '../hooks/permissions'

const resultsNotFoundTitle = 'Survey results were not found'

const getTimelineFilterConfigNoDataDescription = (
  timelineFilter: TimelineFilterInterface | undefined,
  surveysOptionsNum: number | undefined,
  isEngagementManager: boolean,
) => {
  const unknownError = {
    title: resultsNotFoundTitle,
    description: isEngagementManager
      ? 'You should run a survey and publish results to see them here'
      : undefined,
  }
  const roundsOptionsNum = timelineFilter?.round.options.length
  const selectedRound = timelineFilter?.round.value

  if (timelineFilter?.mode.value !== 'rounds') {
    return unknownError
  }
  if (roundsOptionsNum === 0 && typeof surveysOptionsNum === 'number') {
    return {
      title:
        surveysOptionsNum > 1
          ? 'This survey does not have completed rounds'
          : resultsNotFoundTitle,
      description: 'At least one survey round should be completed to see the details',
    }
  }
  if (roundsOptionsNum && !selectedRound) {
    return {
      title: 'Survey round is not selected',
      description: 'Please, select survey round to see the results',
    }
  }
  return unknownError
}

type Props = {
  variant?: 'widget' | 'default'
  timelineFilter?: TimelineFilterInterface
  surveysOptionsNum?: number
}
export const NoSurveysPlaceholder = ({
  variant,
  timelineFilter,
  surveysOptionsNum,
}: Props) => {
  const isEngagementManager = useIsEngagementManager()
  const Wrapper = variant === 'widget' ? Widget : React.Fragment

  const { title, description } = getTimelineFilterConfigNoDataDescription(
    timelineFilter,
    surveysOptionsNum,
    isEngagementManager,
  )
  const isDashboard = useRouteMatch([ROUTES.PERFORMANCE.ENGAGEMENT.ANY])

  return (
    <Wrapper>
      <EmptyTableRaw
        title={title}
        description={description}
        action={
          isEngagementManager && !isDashboard ? (
            <ActionButton use={InternalLink} to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}>
              Go to dashboard
            </ActionButton>
          ) : undefined
        }
      />
    </Wrapper>
  )
}
