import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ActionButton,
  Box,
  DragAndDrop,
  Group,
  Side,
  Tag,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { move } from '@src/utils/move'
import SideBar from '@components/SideBar/SideBar'
import { DNDSortableItem } from '@components/DragAndDrop/SortableItem'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsGroupSourceType,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { DNDOverlayItem } from '@components/DragAndDrop/OverlayItem'
import { DNDAddableItem } from '@components/DragAndDrop/AddableItem'
import {
  getMetricSourceAvatar,
  getMetricSourceLabel,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { ExternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { getMetricSourceDescription } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/constants'
import { ExternalSourceOptionAvailability } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Extrernal/hooks/useSourcesOptionsAvailability'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { getMetricSourceIntegrationLink } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Extrernal/constants'
import { useDNDSourceItem } from '@components/DragAndDrop/hooks/useDNDSourceItem'
import { metricSourceModel } from '@src/features/AiInsights/Performance/models/metricSourceModel'

interface Props {
  sourceOptions: ExternalSourceOptionAvailability[]
  isOpen: boolean
  actions: React.ReactNode
  onClose: VoidFunction
}

export const ExternalSourcesSidebar = ({
  sourceOptions,
  isOpen,
  actions,
  onClose,
}: Props) => {
  const { values } = useLapeContext<PerformanceInsightsGroupInterface>()

  const sortableSources = values.sources.map(source => ({ ...source, id: source.name }))
  const addableOptions = sourceOptions.filter(
    ({ name }) => !sortableSources.find(({ id }) => id === name),
  )

  const handleAdd = (id: string) => {
    const sourceOption = sourceOptions.find(option => option.name === id)

    if (!sourceOption) {
      return
    }

    const { enabled, ...source } = sourceOption
    const enabledMetrics = sourceOption.metrics.map(metric => ({
      ...metric,
      enabled: true,
    }))

    const firstInternalSourceIndex = values.sources.findIndex(
      metricSourceModel.isInternal,
    )
    const insertExternalSourceIndex =
      firstInternalSourceIndex !== -1 ? firstInternalSourceIndex : values.sources.length
    values.sources.splice(insertExternalSourceIndex, 0, {
      ...source,
      metrics: enabledMetrics,
    })
  }

  const handleRemove = (id: string) => {
    values.sources = values.sources.filter(({ name }) => name !== id)
  }

  const handleReorder = useCallback(
    (startIndex: number, endIndex: number) => {
      values.sources = move(sortableSources, startIndex, endIndex)
    },
    [values.sources],
  )

  const { activeItemId, onDragStart, onDragEnd, onDragCancel } =
    useDNDSourceItem<ExternalMetricSources>({ onAfterDragEnd: handleReorder })

  return (
    <SideBar
      variant="wide"
      title={
        <FormattedMessage
          id="forms.performance.insights.group.sources.sidebar.title"
          defaultMessage="Manage data sources"
        />
      }
      subtitle={
        <FormattedMessage
          id="forms.performance.insights.group.sources.sidebar.subtitle"
          defaultMessage="Choose what columns you want to have in the table and in what order"
        />
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      <VStack space="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          // @ts-ignore
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable id="sortable-table-preferences" items={sortableSources}>
              {sortable => {
                const source = sortableSources.find(
                  item =>
                    item.id === sortable.id &&
                    item.type === PerformanceInsightsGroupSourceType.External,
                )

                return source ? (
                  <DNDSortableItem
                    title={getMetricSourceLabel(source.name)}
                    description={getMetricSourceDescription(source.name)}
                    avatar={getMetricSourceAvatar(source.name)}
                    side={
                      <Tag variant="outlined" color={Token.color.deepGrey} useIcon="Link">
                        <FormattedMessage
                          id="integrations.common.connected.label"
                          defaultMessage="Connected"
                        />
                      </Tag>
                    }
                    sortable={sortable}
                    onClick={() => handleRemove(source.name)}
                  />
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeItemId && (
              <DNDOverlayItem
                title={getMetricSourceLabel(activeItemId)}
                description={getMetricSourceDescription(activeItemId)}
                avatar={getMetricSourceAvatar(activeItemId)}
                side={
                  <Tag variant="outlined" color={Token.color.deepGrey} useIcon="Link">
                    <FormattedMessage
                      id="integrations.common.connected.label"
                      defaultMessage="Connected"
                    />
                  </Tag>
                }
              />
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        <Group>
          {addableOptions.map(({ name, enabled }) => {
            return (
              <Box key={name} aria-label={name}>
                <DNDAddableItem
                  title={getMetricSourceLabel(name)}
                  description={getMetricSourceDescription(name)}
                  avatar={getMetricSourceAvatar(name)}
                  side={
                    enabled ? (
                      <Tag variant="outlined" color={Token.color.deepGrey} useIcon="Link">
                        <FormattedMessage
                          id="integrations.common.connected.label"
                          defaultMessage="Connected"
                        />
                      </Tag>
                    ) : (
                      <ActionButton
                        variant="accent"
                        use={InternalLink}
                        to={pathToUrl(getMetricSourceIntegrationLink(name))}
                      >
                        <FormattedMessage
                          id="integrations.common.connect.cta.label"
                          defaultMessage="Connect"
                        />
                      </ActionButton>
                    )
                  }
                  disabled={!enabled}
                  onClick={() => handleAdd(name)}
                />
              </Box>
            )
          })}
        </Group>
      </VStack>
      <Side.Actions variant="horizontal">{actions}</Side.Actions>
    </SideBar>
  )
}
