import React from 'react'

import {
  Widget,
  Box,
  Text,
  Subheader,
  HStack,
  VStack,
  Skeleton,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { useGetInterviewEvaluation } from '@src/api/recruitment/interviews'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { CVScore } from '@src/features/CVInsightsWidget/components/CVScore'
import { CVInsightsList } from '@src/features/CVInsightsWidget/components/CVInsightsList'
import { CVSkillsList } from '@src/features/CVInsightsWidget/components/CVSkillsList'
import { FeedbackItem } from '@src/features/FeedbackItem/FeedbackItem'

interface CVInsightsWidgetProps {
  roundId: number
  specialisationId?: number
}

export const CVInsightsWidget = ({
  roundId,
  specialisationId,
}: CVInsightsWidgetProps) => {
  const { data: roundEvaluation, isLoading: loadingInterviewEvaluation } =
    useGetInterviewEvaluation(roundId)

  return (
    <Widget pb="s-16">
      <Subheader variant="nested">
        <Subheader.Title>
          <HStack align="center" space="s-8" px="s-16">
            <AiIcon />
            <Text>CV Insights</Text>
          </HStack>
        </Subheader.Title>
      </Subheader>

      {loadingInterviewEvaluation ? (
        <>
          <ItemSkeleton />
          <Box p="s-16">
            <VStack space="s-16">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </VStack>
          </Box>
        </>
      ) : (
        <>
          <CVScore
            score={roundEvaluation?.candidate_score}
            specialisationId={specialisationId}
          />
          <VStack space="s-16">
            <CVSkillsList evaluations={roundEvaluation?.evaluations || []} />
            <CVInsightsList
              evaluations={roundEvaluation?.evaluations || []}
              type="MUST_HAVE"
              title="Must haves"
            />
            <CVInsightsList
              evaluations={roundEvaluation?.evaluations || []}
              type="NICE_TO_HAVE"
              title="Nice to haves"
            />
            <CVInsightsList
              evaluations={roundEvaluation?.evaluations || []}
              type="RED_FLAG"
              title="Potential red flags"
            />
          </VStack>
          <FeedbackItem
            objectId={roundId}
            objectType="interviewround"
            topic="recruitment"
            title="How accurate is this insight?"
            labelYes="Accurate"
            labelNo="Not accurate"
          />
        </>
      )}
    </Widget>
  )
}
