import React from 'react'
import { HStack, Icon, Text, Token, Tooltip, useTooltip, VStack } from '@revolut/ui-kit'

interface Props {
  label: string
  title: string
  subtitle: string
}

export const EmployeeProgressNaviagationLabel = ({ label, title, subtitle }: Props) => {
  const tooltip = useTooltip()

  return (
    <HStack align="center" space="s-8" width="fit-content">
      <Text>{label}</Text>
      <Icon {...tooltip.getAnchorProps()} name="InfoOutline" size={16} />
      <Tooltip {...tooltip.getTargetProps()} placement="auto-start">
        <VStack padding="s-12" gap="s-4" maxWidth={337}>
          <Text variant="emphasis2" color={Token.color.foreground}>
            {title}
          </Text>

          <Text variant="body3" color={Token.color.greyTone50}>
            {subtitle}
          </Text>
        </VStack>
      </Tooltip>
    </HStack>
  )
}
