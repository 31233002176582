import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, DragAndDrop, Group, Side, VStack } from '@revolut/ui-kit'
import { move } from '@src/utils/move'
import SideBar from '@components/SideBar/SideBar'
import { DNDSortableItem } from '@components/DragAndDrop/SortableItem'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsGroupSourceType,
  PerformanceInsightsSourceOption,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { DNDOverlayItem } from '@components/DragAndDrop/OverlayItem'
import { DNDAddableItem } from '@components/DragAndDrop/AddableItem'
import {
  getMetricSourceAvatar,
  getMetricSourceLabel,
} from '@src/features/AiInsights/Performance/components/Metrics/common/constants'
import { InternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'
import { getMetricSourceDescription } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/constants'
import { useDNDSourceItem } from '@components/DragAndDrop/hooks/useDNDSourceItem'

interface Props {
  sourceOptions: PerformanceInsightsSourceOption<InternalMetricSources>[]
  isOpen: boolean
  actions: React.ReactNode
  onClose: VoidFunction
}

export const InternalSourcesSidebar = ({
  sourceOptions,
  isOpen,
  actions,
  onClose,
}: Props) => {
  const { values } = useLapeContext<PerformanceInsightsGroupInterface>()

  const sortableSources = values.sources.map(source => ({ ...source, id: source.name }))
  const addableOptions = sourceOptions.filter(
    ({ name }) => !sortableSources.find(({ id }) => id === name),
  )

  const handleAdd = (id: string) => {
    const sourceOption = sourceOptions.find(option => option.name === id)

    if (!sourceOption) {
      return
    }

    const enabledMetrics = sourceOption.metrics.map(metric => ({
      ...metric,
      enabled: true,
    }))

    values.sources = [...values.sources, { ...sourceOption, metrics: enabledMetrics }]
  }

  const handleRemove = (id: string) => {
    values.sources = values.sources.filter(({ name }) => name !== id)
  }

  const handleReorder = useCallback(
    (startIndex: number, endIndex: number) => {
      values.sources = move(sortableSources, startIndex, endIndex)
    },
    [values.sources],
  )

  const { activeItemId, onDragStart, onDragEnd, onDragCancel } =
    useDNDSourceItem<InternalMetricSources>({ onAfterDragEnd: handleReorder })

  return (
    <SideBar
      variant="wide"
      title={
        <FormattedMessage
          id="forms.performance.insights.group.sources.sidebar.title"
          defaultMessage="Manage data sources"
        />
      }
      subtitle={
        <FormattedMessage
          id="forms.performance.insights.group.sources.sidebar.subtitle"
          defaultMessage="Choose what columns you want to have in the table and in what order"
        />
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      <VStack space="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          // @ts-ignore
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable id="sortable-table-preferences" items={sortableSources}>
              {sortable => {
                const source = sortableSources.find(
                  item =>
                    item.id === sortable.id &&
                    item.type === PerformanceInsightsGroupSourceType.Internal,
                )

                return source ? (
                  <DNDSortableItem
                    title={getMetricSourceLabel(source.name)}
                    description={getMetricSourceDescription(source.name)}
                    avatar={getMetricSourceAvatar(source.name)}
                    sortable={sortable}
                    onClick={() => handleRemove(source.name)}
                  />
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeItemId && (
              <DNDOverlayItem
                title={getMetricSourceLabel(activeItemId)}
                description={getMetricSourceDescription(activeItemId)}
                avatar={getMetricSourceAvatar(activeItemId)}
              />
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        <Group>
          {addableOptions.map(({ name }) => {
            return (
              <Box key={name}>
                <DNDAddableItem
                  title={getMetricSourceLabel(name)}
                  description={getMetricSourceDescription(name)}
                  avatar={getMetricSourceAvatar(name)}
                  onClick={() => handleAdd(name)}
                />
              </Box>
            )
          })}
        </Group>
      </VStack>
      <Side.Actions variant="horizontal">{actions}</Side.Actions>
    </SideBar>
  )
}
