import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { Group, Item, Avatar, VStack } from '@revolut/ui-kit'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'
import {
  CandidateCoordinator,
  CandidateCreatedBy,
  CandidateCurrentCompany,
  CandidateCurrentCountry,
  CandidateCurrentLocation,
  CandidateCurrentPosition,
  CandidateCurrentStage,
  CandidateDesiredSalary,
  CandidateEmail,
  CandidateHiringManager,
  CandidateInterviewer,
  CandidateJobPosting,
  CandidateLinks,
  CandidateOrigin,
  CandidatePhone,
  CandidatePreferredLocations,
  CandidateRecruiter,
  CandidateRequisition,
  CandidateSeniority,
  CandidateSpecialisation,
} from '@src/features/InterviewTool/components/CandidateDataAtoms'

interface Props {
  round?: InterviewRoundInterface
  interviewer?: InterviewToolInterviewer
  candidate: CandidateInterface
}

export const InterviewFeedbackSideInfo = ({ round, interviewer, candidate }: Props) => {
  return (
    <VStack gap="s-16">
      <WorkExperienceInfo
        data={candidate.work_experiences}
        years={candidate.years_of_experience}
      />
      <EducationInfo data={candidate.educations} />
      <Group>
        <Item aria-label="Personal details">
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Personal details</Item.Title>
          </Item.Content>
        </Item>
        <CandidateEmail candidate={candidate} />
        <CandidatePhone candidate={candidate} />
        <CandidateLinks candidate={candidate} />
        <CandidateCurrentCompany candidate={candidate} />
        <CandidateCurrentLocation candidate={candidate} />
        <CandidateCurrentCountry candidate={candidate} />
        <CandidateCurrentPosition candidate={candidate} />
      </Group>
      <Group>
        <Item aria-label="Opportunity details">
          <Item.Avatar>
            <Avatar useIcon="Microphone" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Opportunity details</Item.Title>
          </Item.Content>
        </Item>
        <CandidateCurrentStage stage={round?.latest_interview_stage?.title} />
        <CandidateInterviewer interviewer={interviewer} />
        <CandidateSeniority round={round} />
        <CandidateSpecialisation round={round} />
        <CandidateJobPosting round={round} />
        <CandidateRecruiter recruiter={round?.recruiter} />
        <CandidateCoordinator coordinator={round?.coordinator} />
        <CandidateHiringManager hiringManager={round?.hiring_manager} />
        <CandidateOrigin round={round} />
        <CandidateCreatedBy round={round} />
        <CandidateRequisition round={round} />
        <CandidatePreferredLocations round={round} />
        <CandidateDesiredSalary round={round} />
      </Group>
    </VStack>
  )
}
